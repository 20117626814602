// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;
// const role = window.localStorage.getItem('role');

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  products: getIcon('ic_products'),
  ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    // banking: getIcon('ic_banking'),
    // booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'orders', path: PATH_DASHBOARD.general.orders, icon: ICONS.ecommerce },
      { title: 'reviews', path: PATH_DASHBOARD.general.reviews, icon: ICONS.ecommerce },
      { title: 'brands', path: PATH_DASHBOARD.general.brands, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  {
    subheader: 'User',
    items: [
      { title: 'Users', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  {
    subheader: 'Mediators',
    items: [
      { title: 'Mediators', path: PATH_DASHBOARD.mediator.root, icon: ICONS.user },
      {title:'Products', path: PATH_DASHBOARD.product.root, icon: ICONS.products}
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
];

export default navConfig;
