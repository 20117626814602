import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="55" height="55" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="72" height="72" rx="13" fill="url(#paint0_linear_1138_2588)" />
        <path d="M34.4435 14.9951L34.4247 35.9887L34.4294 36.1391C34.4341 36.1955 34.4435 36.2519 34.4529 36.3083C34.4012 36.7313 34.5234 37.1542 34.7913 37.4832L34.9041 37.6101L49.1741 52.3954C40.1026 59.6518 26.862 58.1808 19.6049 49.1103C12.3477 40.0399 13.8189 26.8008 22.8903 19.5444C26.1946 16.8985 30.2227 15.3147 34.4435 14.9951ZM37.5833 34.3955L37.5974 14.9951C47.9708 15.7752 56.215 24.0185 57 34.3908L37.5833 34.3955Z" fill="white" />
        <path d="M38.7395 36.918L56.5064 36.9133C56.1584 41.646 54.2127 46.1202 50.9883 49.6025L38.7442 36.918L56.5111 36.9133H38.7442V36.918H38.7395Z" fill="#FFF27B" />
        <defs>
          <linearGradient id="paint0_linear_1138_2588" x1="-0.39779" y1="36.2009" x2="77.409" y2="72.1967" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor="#D9214D" />
            <stop offset="100%" stopColor="#FFB876" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
