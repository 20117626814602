// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ADMIN_DASHBOARD = '/admin/dashboard'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const ADMIN_PATH_DASHBOARD = {
  root: ADMIN_DASHBOARD,
  general: {
    app: path(ADMIN_DASHBOARD, '/app'),
  }
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    reviews: path(ROOTS_DASHBOARD, '/reviews'),
    brands: path(ROOTS_DASHBOARD, '/brands'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  mediator:{
    root: path(ROOTS_DASHBOARD, '/mediator'),
  },
  product:{
    root: path(ROOTS_DASHBOARD, '/product'),
  }
};

