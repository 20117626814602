import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Admin Dashboard Routes
    {
      path: '/dashboard',
      element: (
        <AuthGuard >
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {path: 'orders', element:<OrderLists/>},
        {path:'reviews', element:<ReviewLists/>},
        {path:'brands', element:<BrandList/>},
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':id/details', element: <UserProfile /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'mediator',
          children: [
            { element: <Navigate to="/dashboard/mediator/list" replace />, index: true },
            { path: 'list', element: <MediatorList /> },
            { path: ':id/details', element: <MediatorProfile /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/product/list" replace />, index: true },
            { path: 'list', element: <ProductList /> },
            // { path: ':id/details', element: <MediatorProfile /> },
            // { path: 'account', element: <UserAccount /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const OrderLists = Loadable(lazy(()=> import('../pages/dashboard/OrderList')));
const ReviewLists = Loadable(lazy(()=> import('../pages/dashboard/RefundList')));
const BrandList = Loadable(lazy(() => import('../pages/dashboard/Brands/BrandList')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/Users/UserProfile')));
const MediatorList = Loadable(lazy(()=> import('../pages/dashboard/Mediators/MediatorList')));
const MediatorProfile = Loadable(lazy(()=> import('../pages/dashboard/Mediators/MediatorProfile')));
const ProductList = Loadable(lazy(()=> import('../pages/dashboard/Products/ProductList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));